/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 99999 !important;
 }

 .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    background-color:#d3d3d3;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .mycontainer {
     box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
     transition: 0.3s;
     width: 100%;
     background-color:#ffffff;
     margin-left: auto;
     margin-right: auto;
     display: block;
     padding: 0%; 
     padding-bottom: 10px;
    
  }
  
  .image-container{
     /* width:50%; */
     margin-top: 0rem; 
     margin-bottom: 0.5rem;
  }
  
  .small-card{
       
      height:80px;
      padding:0.1rem; 
      margin-top: -2rem; 
  
  }

  .no-left-padding{
   padding-left: 0;
}

  
  .small-card-content{
     height:80px;
     box-shadow: 0px 3px 3px 1.5px #ebebeb;
     margin-left: 0.2rem; 
      margin-right: 0.2rem; 
      border-radius: 0.3rem;
      background-color: #ffffff;
  
  }
  
  .card-text{
     color: #505050;
     display: block; 
     font-weight: 500; 
     font-size: 12px; 
     line-height: 30px;
  }
  
  .card-image{
     font-size: 46px; 
     text-align: center;
     line-height: 40px;
     color: #979797;
  }
  
  .contact-section{
      margin-bottom: 0.6rem; 
      border-bottom: 1px solid #f1f1f1; 
      padding-bottom: 0.3rem;
  }
  
  .address-text{
     font-size: 1.4rem; 
     line-height: 1.6rem; 
     color: #a0a0a0;
     display:flex; 
     font-weight: 300;
  }
  
  .header {
      text-align: center;
      padding-top: 5px;
  }
  
  .header-content{
     margin: 0px; 
     padding-top: 10px;
  }
  
  .myimages{
     height: 210px; 
     width: 330px;
  }
  
  
  .social-block{
     padding-left: 1.0rem; 
     border-radius: 5px; 
     height: 60px; 
     margin: 2px; 
     padding: 4px;
     width: 100%;
  }
  
  .service-badge{
     font-size: 14px; 
     line-height: 14px; 
     font-weight: 300; 
     color: #ffffff;
     display:flex; 
     background-color: #5a5a5a; 
     display: inline-block; width: auto; 
     margin: 2px; 
     padding: auto;
  }
  
  .capitalise{
     text-transform:capitalize;
  }
  
  .lowercase{
     text-transform:lowercase;
  }

  .social-icon{
    font-size: 48px; 
    color: #ffffff;
  }

  .service-badge{
   font-size: 14px; 
   line-height: 14px; 
   font-weight: 300; 
   color: #ffffff;
   display:flex; 
   background-color: #5a5a5a; 
   display: inline-block; width: auto; 
   margin: 2px; 
   padding: 5px;
   border-radius:15px;
}

.service-badge-form{
   font-size: 14px; 
   line-height: 14px; 
   font-weight: 300; 
   color: #202020;
   display:flex; 
   background-color: #ff7b00; 
   display: inline-block; width: auto; 
   margin: 2px; 
   padding: 5px;
   border-radius:15px;

}
.shadow-bg{
   background-color: #ffffff;
   box-shadow: 5px 10px 10px 5px #888888;
}

.text-input{
   border-width: 0px;;
}

.row-header{
   margin-bottom: 10px;
   font-size: 0.6rem; 
   line-height: 1.0rem; 
   text-align: left; 
   background-color: #464646; 
   border-radius: 5px; 
   padding:5px;
   cursor: pointer;
}

.row-header :hover{
   color: #ff7b00;
}

.row-header-title{
  font-size: 1.0rem; 
  line-height: 1.0rem; 
  color: #ffffff;
  text-align: left;
  font-weight: 400;
}

.row-header-text{
   font-size: 0.8rem; 
   line-height: 1.0rem; 
   color: #ffffff;
   text-align: left;
 }

 .row-header-icon{
   font-size: 1.0rem; 
   line-height: 1.0rem; 
   color: #ffffff;
   text-align: left;
   font-weight: 400;
 }

 .dropzone{
   height: 150px;
   min-width: 224px;
   display: table;
   background-color: #eee;
   border: dashed 1px #aaa;
   border-radius: 10px;
   margin-left: auto;
   margin-right: auto;
 }

 .text-wrapper{
   display: table-cell;
   vertical-align: middle;
 }

 .center{
   text-align: center;
 }

 .browse-button{
   color: blue;
   cursor: pointer;
 }

 

 select {
   -webkit-appearance: listbox !important;
   }
  
  @media (min-width: 786px){
  .mycontainer, .mycontainer-sm, .mycontainer-md, .mycontainer-lg {
      
      display: block;
      margin-left: 30%;
      margin-right: auto;
      width: 480px;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  
  }
  
  @media (max-width: 786px){
  .mycontainer, .mycontainer-sm, .mycontainer-md, .mycontainer-lg {
      width: 100%;
      
  }
  
  .myimages{
     width: 400px;
  }
  
  .social-block{
     width: 380px;
  }
  
  }
  
  @media (max-width: 480px){
  
  .card-text{
     font-size: 10px; 
  }
  
  }

  .login-panel
{
  width: 100%;
  text-align: center;
  padding-top: 50px;
  color: white;
  overflow: hidden;
  background: #989898;  /* fallback for old browsers */
  background: linear-gradient(rgba(138, 138, 138, 0.1), rgba(138, 138, 138, 0.1)), url("https://app.goodchat.co.za/images/goodchatimage.jpg");  
  background: linear-gradient(rgb(138, 138, 138, 0.1), rgb(138, 138, 138, 0.1)), url("https://app.goodchat.co.za/images/goodchatimage.jpg");
  background-size: cover;
  background-repeat:repeat;
}

.login-panel-text{
  font-size: 30px; 
  font-weight: 600; 
  text-align: center; 
  color: #ffffff; 
  line-height: 30px;
}

.login-panel-inner{
  bottom: 30px; 
  position: absolute;
}

.login-form-section{
  width: 500px; 
  margin-left: auto; 
  margin-right: auto;
  padding-top: 50px;
}

.login-logo{
  text-align: center; 
  margin-top: 40px;
}

.copy-bot{
   padding-top: 5px; 
   margin-top: 20px; 
   text-align: center; 
   position: fixed; 
   right: 40px; 
   top: 50px;
}

.main{
   display: block;
   float: right;  
   z-index: 999;
   position: fixed; /*newly added*/
   right: 40px; /*newly added*/
   bottom: 80px;/*newly added*/ 
    
}

.test-bot{
   background-color: #f1f1f1; 
   position: relative; 
   text-align: center;
}


@media (max-width: 576px){
   .login-panel{
     height: 140px;
     /* display: none; */

   }

   .login-panel-text{
     font-size: 18px;
   }

   .login-panel-inner{
     top: 5px;
   }

   .login-form-section{
     width: 440px;
     padding-top: 10px;
   }

   .login-logo{
     margin-top: 10px;
   }

   .copy-bot{
      padding-top: 5px; 
      margin-top: 20px; 
      text-align: center; 
      position: relative; 
      right: 0px; 
      top: 0px;
  }

  .main{
   display: block;
   float: right;  
   z-index: 999;
  
   right: 40px; /*newly added*/
   bottom: 0px;/*newly added*/ 
    
}

.test-bot{
   background-color: #f1f1f1; 
   position: relative; 
   text-align: center;
   
}

 }
  
